@import url(https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap);
body {
    margin: 0;
    height: 100vh;
}


#root{
    height: 100vh;
    width: 100vw;
    background-color: rgb(3, 3, 3);
    display: flex;
    overflow: hidden;
    grid-gap : 10px;
    gap : 10px
}

@media all and (max-width:1024px) {
    #root{ 
        height: -webkit-fit-content; 
        height: -moz-fit-content; 
        height: fit-content;
        min-height: 100%;
    }  
    html{
        overflow-y: auto;
        min-height: 100%;
    }  
}
.side::-webkit-scrollbar-track
{
  background-color: #242c42;
  height: 5px;
  width: 5px;
}

.side::-webkit-scrollbar
{
  background-color: #F5F5F5;
  height: 5px;
  width: 5px;
}

.side::-webkit-scrollbar-thumb:hover
{
  background-color: #888888;
}


.side::-webkit-scrollbar-thumb
{
  background-color: #b4b4b4;
  border-radius: 10px;
  height: 5px;
  width: 5px;
}

.App {
  min-height: 100%;
  min-width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: minmax(30px,auto) auto 1fr;
  grid-template-rows: minmax(30px,auto) auto 1fr;
  -ms-grid-columns: 100fr 37fr;
  grid-template-columns: 100fr 37fr;
  grid-template-areas: 
    "side-header side-header"
    "alert side"
    "img-preiveiw side";
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.img_alert{
  grid-area: alert;
}



.priveiw-rapping{
  -ms-grid-row: 2;
  -ms-grid-row-span: 2;
  -ms-grid-column: 1;
  grid-area: img-preiveiw;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
}

/* full screen(non protect radio) */
/* .img_preview{
  width: 100%;
  height: 100%;
} */


.img_preview {
  width : 100%;
  height: 100%;
  object-fit :contain;
}




hr{
  border-color: #90909096;
}

@media all and (max-width:1024px) {
  .App {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow-y: auto;
    overflow-x: hidden;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 100vh;
  }
  .priveiw-rapping{
    flex: 1 1;
    order:2;
    min-width: auto;
    max-width: auto;
    min-height: auto;
    max-height: auto;
  }

  .non-priveiw{

    font-size: 1rem;
  }
}

.canvers{
  position: absolute;
  top:0;
  left : 0;
}

.bothRapper{
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 1fr;
}

.screenblru{
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #191919c4;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.non-priveiw{
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
}

.title{
  color: #a8a8a8;
  font-size: 1rem;
  text-transform: none;
  /* font-family: 'Kaushan Script', cursive; */
}

.maintitle{
  color: white;
  font-size: 2.1rem;
  font-family: 'Kaushan Script', cursive;
}
.side-header{
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: side-header;
  border-bottom: 1.3px solid gray;
  color: aliceblue;
  transition: width 300ms cubic-bezier(1, 0.03, 0.58, 1) 0ms;
  background-color: #102027;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}

@media all and (max-width:1024px) {

  .side-header{
    order:1;
    border-left: none;
    border-bottom: 1.3px solid gray;
  }
  
}

.side{
  -ms-grid-row: 2;
  -ms-grid-column: 2;
  grid-area: side;
  background: #242c42;
  border-left: 1.3px solid gray;
  padding: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}

@media all and (max-width:1024px) {
  .side{
    order:3;
    flex: 1 1;
    background: #37474f;
    border-left: none;
    border-top: 1.3px solid gray;
    overflow-y: hidden;
    overflow-x: hidden;
  }
  
}

.loading-rapping{
  width: 100%;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.result-rapper{
  max-width: 100%;
  flex-basis: 100%;
  margin: 10px;
  display: flex;
  grid-gap : 10px;
  gap : 10px;
  flex-direction: column;
}

.buttonLoding{
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
