body {
    margin: 0;
    height: 100vh;
}


#root{
    height: 100vh;
    width: 100vw;
    background-color: rgb(3, 3, 3);
    display: flex;
    overflow: hidden;
    gap : 10px
}

@media all and (max-width:1024px) {
    #root{ 
        height: fit-content;
        min-height: 100%;
    }  
    html{
        overflow-y: auto;
        min-height: 100%;
    }  
}