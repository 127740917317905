.side{
  -ms-grid-row: 2;
  -ms-grid-column: 2;
  grid-area: side;
  background: #242c42;
  border-left: 1.3px solid gray;
  padding: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}

@media all and (max-width:1024px) {
  .side{
    -webkit-box-ordinal-group:4;
        -ms-flex-order:3;
            order:3;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    background: #37474f;
    border-left: none;
    border-top: 1.3px solid gray;
    overflow-y: hidden;
    overflow-x: hidden;
  }
  
}

.loading-rapping{
  width: 100%;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.result-rapper{
  max-width: 100%;
  flex-basis: 100%;
  margin: 10px;
  display: flex;
  gap : 10px;
  flex-direction: column;
}

.buttonLoding{
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}