@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');


.side::-webkit-scrollbar-track
{
  background-color: #242c42;
  height: 5px;
  width: 5px;
}

.side::-webkit-scrollbar
{
  background-color: #F5F5F5;
  height: 5px;
  width: 5px;
}

.side::-webkit-scrollbar-thumb:hover
{
  background-color: #888888;
}


.side::-webkit-scrollbar-thumb
{
  background-color: #b4b4b4;
  border-radius: 10px;
  height: 5px;
  width: 5px;
}

.App {
  min-height: 100%;
  min-width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: minmax(30px,auto) auto 1fr;
  grid-template-rows: minmax(30px,auto) auto 1fr;
  -ms-grid-columns: 100fr 37fr;
  grid-template-columns: 100fr 37fr;
  grid-template-areas: 
    "side-header side-header"
    "alert side"
    "img-preiveiw side";
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.img_alert{
  grid-area: alert;
}



.priveiw-rapping{
  -ms-grid-row: 2;
  -ms-grid-row-span: 2;
  -ms-grid-column: 1;
  grid-area: img-preiveiw;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
}

/* full screen(non protect radio) */
/* .img_preview{
  width: 100%;
  height: 100%;
} */


.img_preview {
  width : 100%;
  height: 100%;
  -o-object-fit :contain;
  object-fit :contain;
}




hr{
  border-color: #90909096;
}

@media all and (max-width:1024px) {
  .App {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    overflow-y: auto;
    overflow-x: hidden;
    height: fit-content;
    min-height: 100vh;
  }
  .priveiw-rapping{
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    -webkit-box-ordinal-group:3;
        -ms-flex-order:2;
            order:2;
    min-width: auto;
    max-width: auto;
    min-height: auto;
    max-height: auto;
  }

  .non-priveiw{

    font-size: 1rem;
  }
}

.canvers{
  position: absolute;
  top:0;
  left : 0;
}

.bothRapper{
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 1fr;
}

.screenblru{
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #191919c4;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.non-priveiw{
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
}

.title{
  color: #a8a8a8;
  font-size: 1rem;
  text-transform: none;
  /* font-family: 'Kaushan Script', cursive; */
}

.maintitle{
  color: white;
  font-size: 2.1rem;
  font-family: 'Kaushan Script', cursive;
}